<template>
  <div class="lookwork">
    <div class="Header" ref="Header">
      <div class="menu" @click="openhome"></div>
      <div class="logo"></div>
    </div>
    <div class="viewMap" :style="bgImg">
      <Danmaku ref="Danmaku" class="Danmaku"></Danmaku>
    </div>

    <List ref="List" class="List" :style="listheight"></List>
    <Details ref="Details"></Details>
  </div>

</template>

<script>
import Danmaku from "./exhibit/com/Danmaku";
import List from "./exhibit/com/List";
import {fixed} from "../api/introduce";
import QRCode from "qrcodejs2";
import {setshare} from "../api/exhibit";
import Details from '../components/Details'

export default {
  data() {
    return {
      Details: true,
      data: {},
      bcurl: 'https://hz-mayi.nos-eastchina1.126.net/2021-11-1/2021-10-11_opbgs.jpg',
      listheight: '',
      titleNumber: '',
      bgImg: '',
      SidNumber: ''
    }
  },
  created() {
    let listheight = document.documentElement.clientHeight - 60 - 250
    listheight = 'height:' + listheight + 'px'
    this.listheight = listheight
    let SidNumber = this.$route.query.SidNumber
    this.SidNumber = SidNumber
    this.init()
    this.addNumber()
    if (SidNumber) {
      this.$refs.Details.openImg( SidNumber)
      setTimeout(() => {
        this.createQrcode('http://47.111.1.101:8003?SidNumber=' + SidNumber)
      }, 500)
    }
  },
  components: {
    List,
    Danmaku,
    Details
  },
  methods: {
    addNumber() {
      let terminal = navigator.userAgent
      let sidNumber = this.SidNumber
      setshare({sidNumber, terminal}).then()


    },
    createQrcode(text) {
      // 生成二维码
      const qrcodeImgEl = this.$refs.qrcodeImg
      qrcodeImgEl.innerHTML = "";
      this.qrcode = new QRCode(qrcodeImgEl, {
        width: 75,
        height: 75,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      this.qrcode.makeCode(text);

      setTimeout(() => {
        this.initcanvas()
      }, 1000)
    },
    openhome() {
       this.$router.push('/')
    },
    init() {
      fixed().then(res => {
        let num = res.num
        let bg = `background-image: url(${num.bgImg})`
        this.bgImg = bg
        this.$refs.List.init(num.titleNumber)
        this.titleNumber = num.titleNumber
      })
    },
    login() {
       this.$router.push('/')
    },
    closeImg() {
      this.Details = false
    }
  }
}
</script>

<style scoped lang="scss">
.lookwork {
  width: 100%;
  height: 100%;

  .Header {
    width: 100%;
    height: 60px;
    position: relative;
    background-color: #FFFFFF;

    .menu {
      width: 22px;
      height: 22px;
      position: absolute;
      left: 10px;
      top: 50%;
      margin-top: -11px;
      background-image: url("../assets/image/header/feather.png");
      background-size: 100% 100%;
    }

    .logo {
      width: 215px;
      height: 21px;
      position: absolute;
      left: 50%;
      margin-left: -107.5px;
      top: 50%;
      margin-top: -10.5px;
      background-image: url("../assets/image/header/logo125.png");
      background-size: 100% 100%;
      //background-position: center center;
      //background-repeat: no-repeat;
    }
  }

  .viewMap {
    width: 100%;
    height: 250px;
    position: relative;
    background-color: #f0f0f0;
    background-size: 100% 100%;

    .Danmaku {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  .Details {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 50;

    .bgsimg {
      width: 300px;
      height: 550px;
      background-color: #973733;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -150px;
      margin-top: -300px;
    }

    .main {
      width: 300px;
      height: 570px;
      background-color: #FFFFFF;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -150px;
      margin-top: -285px;

      .titleNav {
        width: 230px;
        height: 35px;
        position: absolute;
        left: 50%;
        margin-left: -109px;
        top: 13px;
        background-image: url("../assets/image/exhibit/titles.png");
        background-size: 100% 100%;
      }

      .qrcodeImg {
        position: absolute;
        width: 75px;
        height: 75px;
        bottom: 45px;
        right: 31px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .reset {
        width: 24px;
        height: 24px;
        background-image: url("../assets/image/map/close.png");
        background-size: 100% 100%;
        position: absolute;
        right: -30px;
        top: 0;
        background-color: #FFFFFF;
      }

      .down {
        width: 24px;
        height: 24px;
        background-image: url("../assets/image/map/down.png");
        background-size: 100% 100%;
        position: absolute;
        left: 50%;
        margin-left: 13px;
        bottom: -40px;
        background-color: #FFFFFF;
        border-radius: 15px;
      }


      .img {
        width: 230px;
        height: 370px;
        position: absolute;
        left: 35px;
        top: 60px;
        border: 1px solid #973733;

        img {
          width: 100%;
          height: 100%;

        }
      }

      @mixin pos {
        position: absolute;
        font-size: 12px;
      }

      .time {
        position: absolute;
        left: 40px;
        bottom: 50px;
        font-size: 12px;
      }

      .great {
        @include pos;
        left: 40px;
        bottom: 70px;
        //width: 100px;
        height: 20px;

        i {
          margin-right: 10px;
          display: inline-block;
          background-image: url("../assets/image/create/like.png");
          width: 15px;
          height: 15px;
          background-size: 100% 100%;
        }
      }

      .step {
        @include pos;
        left: 40px;
        bottom: 94px;
        //width: 100px;
        height: 20px;

        i {
          margin-right: 10px;
          display: inline-block;
          background-image: url("../assets/image/create/step.png");
          width: 15px;
          height: 15px;
          background-size: 100% 100%;
        }
      }

      .bottom {
        width: 100%;
        height: 30px;
        position: absolute;
        bottom: 0;
        left: 0;
        overflow: hidden;

        p {
          position: absolute;
          color: #FFFFFF;
          left: 0;
          top: -12px;
          text-align: center;
          font-size: 13px;
          width: 100%;
          height: 30px;
          line-height: 30px;
        }

        img {
          position: absolute;
          left: 0;
          top: 0;
        }

        //opacity: 0;
      }

    }

    .bgSum {
      position: absolute;
      z-index: 11;
      width: 300px;
      height: 525px;
    }

    .canvas {
      position: fixed;
      top: 0;
      opacity: 0;

      //left: 50%;
      //top: 50%;
      //margin-left: -150px;
      //margin-top: -289.5px;
      z-index: -10;
    }
  }
}

</style>
